import { useState } from "react"
import ExpandButton from "../ExpandButton/ExpandButton";
import { baseUrl } from "../../../../Config";

const Phase1 = ({ project }) => {
    const [ expandAll, setExpandAll ] = useState(false);

    return <>
        {/* <div className="row">
            <div className="col-12">
                <div className="tax-reform ps-3">
                    { project.titulo_link != null && project.titulo_link != '' ? (
                        <>
                            <a href={ project.titulo_link } target="_blank">{ project?.titulo }</a>
                        </>
                    ) : (
                        <>
                            <p>{ project?.titulo }</p>
                        </>
                    )}
                    <p className="project-subtitle">{ project?.short_description }</p>
                </div>
            </div>
        </div> */}

        <div className="object-container">
            <div className="row">
                <div className="col-12">
                    <ExpandButton
                        expand={expandAll}
                        onClick={() => {
                            setExpandAll((state) => {
                                return !state;
                            });
                        }}
                    />
                </div>
            </div>

            <div className="row accordion mt-4" id="accordionExample">
                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <img src="./assets/images/author.png" alt="Author Icon" /> &nbsp; &nbsp;
                                Autor(es)
                            </button>
                        </h2>

                        <div id="collapseOne" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="headingOne">
                            <div className="accordion-body">
                                {project.authors?.map(author => (
                                    <div className="d-flex align-items-center" key={author.id}>
                                        {/* <div className="me-2">
                                            <img src={`${baseUrl}/members/${author?.image}`} className="authorimg" alt="Author Avatar" />
                                        </div> */}
                                        <div>
                                            {/* <p className="accodian-text-primary">{ author.party }</p> */}
                                            <p className="accodian-text">{ author.name }</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <img src="./assets/images/ponentes.png" alt="Comission Icon" />&nbsp;&nbsp;
                                Comisión
                            </button>
                        </h2>

                        <div id="collapseTwo" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="headingTwo">
                            <div className="accordion-body">
                                <p className="accodian-text">{ project.comission }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading3">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseOne">
                                <img src="./assets/images/paper.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                No. de proyecto
                            </button>
                        </h2>

                        <div id="collapse3" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading3">
                            <div className="accordion-body">
                                <div className="d-flex">
                                    <div className="me-4">
                                        <p className="accodian-text-primary">Cámara</p>
                                        <p className="accodian-text">{ project.camara_no }</p>
                                    </div>
                                    <div>
                                        <p className="accodian-text-primary">Senado</p>
                                        <p className="accodian-text">{ project.senado_no }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                <img src="./assets/images/document.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Legislatura
                            </button>
                        </h2>

                        <div id="collapse4" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading4">
                            <div className="accordion-body">
                            <p className="accodian-text">{ project.legislatura }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                <img src="./assets/images/building.png" alt="Author Icon" /> &nbsp; &nbsp;
                                Origen
                            </button>
                        </h2>

                        <div id="collapse5" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading5">
                            <div className="accordion-body">
                                <p className="accodian-text">{ project.origin }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                <img src="./assets/images/calendar.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Fecha de radicación
                            </button>
                        </h2>

                        <div id="collapse6" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading6">
                            <div className="accordion-body">
                                <p className="accodian-text-primary">{ project.fetcha_origin }</p>
                                <p className="accodian-text">{ project.fetcha_redica }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading7">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                <img src="./assets/images/category.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Tipo
                            </button>
                        </h2>

                        <div id="collapse7" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading7">
                            <div className="accordion-body">
                                <p className="accodian-text">{ project.tipo }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading8">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                <img src="./assets/images/shield.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Objeto de proyecto
                            </button>
                        </h2>

                        <div id="collapse8" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading8">
                            <div className="accordion-body">
                                <p className="accodian-text">{ project.objecto }</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading9">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                <img src="./assets/images/binocular.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Observaciones
                            </button>
                        </h2>

                        <div id="collapse9" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading9">
                            <div className="accordion-body">
                                <div className="observation">
                                    <p className="accodian-text">{ project.observation }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="heading10">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                <img src="./assets/images/document.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                Texto radicado
                            </button>
                        </h2>

                        <div id="collapse10" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading10">
                            <div className="accordion-body">
                                <a href={project.radicado} target="_blank">Ver más aquí</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Phase1